import React from 'react';
import './SubscriptionModal.css';
import api from '../api';

const SubscriptionModal = ({ isOpen, onClose, onSelect, userId, subscriptions, currentSubscriptionId }) => {
  if (!isOpen) return null;

  const handleSubscriptionChange = (subscriptionTypeId) => {
    if (subscriptionTypeId === currentSubscriptionId) {
      alert('У вас уже активирована эта подписка');
      return;
    }

    api.post('/subscription_change', {
      subscription_type_id: subscriptionTypeId,
    })
    .then(response => {
      console.log(response.data);
      const confirmationUrl = response.data.confirmation_url;
      onSelect(subscriptionTypeId);
      onClose();
      // Редирект на URL, возвращенный API
      window.location.href = confirmationUrl;
    })
    .catch(error => {
      console.error('Error changing subscription:', error);
    });
  };

  return (
    <div className="subscription-modal-overlay">
      <div className="subscription-modal">
        <h2 className="subscription-modal-title">Change Subscription</h2>
        <div className="subscription-options">
          {subscriptions.map(sub => (
            <div key={sub.id} className={`subscription-option subscription-${sub.name.toLowerCase()}`} onClick={() => handleSubscriptionChange(sub.id)}>
              <h3 className="subscription-name">{sub.name}</h3>
              <p className="subscription-price">{sub.price}</p>
              <ul className="subscription-products">
                {Object.entries(sub.products).map(([product, amount]) => (
                  <li key={product} className="enabled">{product}: {amount} minutes</li>
                ))}
              </ul>
              <ul className="subscription-privileges">
                {sub.privileges.map((privilege, index) => (
                  <li key={index} className="enabled">{privilege}</li>
                ))}
                {Object.entries(sub.features).map(([feature, available], index) => (
                  <li key={index} className={available ? 'enabled' : 'disabled'}>{feature}</li>
                ))}
              </ul>
              {sub.note && <p className="subscription-note">{sub.note}</p>}
            </div>
          ))}
        </div>
        <button className="subscription-close-btn" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SubscriptionModal;
