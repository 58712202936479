import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './GuestProjectReadyPage.css';
import logo from '../logo_icon.png'; // Замените на путь к вашему логотипу
import projectImage from '../animated_logo.gif';
import api from '../api';

const GuestProjectReadyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { processingId } = location.state || {};
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (processingId) {
      const fetchFinalVideos = async () => {
        try {
          const response = await api.get(`/final_videos/${processingId}`);
          if (response.data) {
            setVideos(response.data);
          } else {
            console.log('No videos found for this processingId');
          }
        } catch (error) {
          console.error('Error fetching final videos:', error);
        }
      };
      fetchFinalVideos();
    }
  }, [processingId]);

  const handleRegisterClick = () => {
    navigate('/login');
  };

  const handleReturnClick = () => {
    navigate('/home');
  };

  return (
    <div className="guest-project-ready-page">
      <div className="main-container-project-ready">
        <div className="project-details">
          <div className="project-header-box">
            <div className="project-header">
              <img src={projectImage} alt="Project" />
              <div className="project-info">
                <h2>Your Final Project</h2>
                <p>{videos.length} Video(s)</p>
              </div>
            </div>
            <div className="button-group">
              <button className="action-button" onClick={handleRegisterClick}>
                Register
              </button>
              <button className="action-button" onClick={handleReturnClick}>
                Return
              </button>
            </div>
          </div>
          <div className="main-video-container">
            {videos.length > 0 ? (
              videos.map((video, index) => (
                <div key={index} className="video-wrapper">
                  <div className="video-container">
                    <video src={video.src} controls className="main-video">
                      <track kind="captions" />
                    </video>
                  </div>
                </div>
              ))
            ) : (
              <p>Loading videos...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestProjectReadyPage;
