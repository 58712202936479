import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../processing_page/ProcessingPage.css';
import api from '../api';

const GuestProcessingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { videoFile, youtubeUrl } = location.state || {};
  const [processingId, setProcessingId] = useState(null);
  const [currentPhrase, setCurrentPhrase] = useState(0);
  const [error, setError] = useState(null);

  const phrases = [
    "Taking a look at your video",
    "Gave your video to our workers (they are manually cutting it)",
    "Processing... Please wait",
    "Almost done... Hang tight!",
    "Still working, man...",
    "Wait a second pls",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhrase((prevPhrase) => (prevPhrase + 1) % phrases.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // Функция для загрузки видеофайла
  const uploadAndProcessVideo = async () => {
    try {
      const formData = new FormData();
      formData.append('video', videoFile);

      const uploadResponse = await api.post('/upload_video', formData);
      const { processing_id } = uploadResponse.data;

      setProcessingId(processing_id);

      // Запускаем обработку с предустановленными настройками
      await api.post('/process_full_pipeline', { process_id: processing_id });

      // Начинаем проверку статуса обработки
      checkProcessingStatus(processing_id);
    } catch (err) {
      setError('Ошибка при загрузке и обработке видео.');
      console.error(err);
    }
  };

  // Функция для загрузки видео с YouTube
  const uploadAndProcessYoutubeVideo = async () => {
    console.log("uploadAndProcessYoutubeVideo called");
    try {
      const uploadResponse = await api.post('/upload_youtube_video', { youtube_url: youtubeUrl });
      const { processing_id } = uploadResponse.data;

      setProcessingId(processing_id);

      // Запускаем обработку с предустановленными настройками
      await api.post('/process_full_pipeline', { process_id: processing_id });

      // Начинаем проверку статуса обработки
      checkProcessingStatus(processing_id);
    } catch (err) {
      setError('Ошибка при обработке YouTube видео.');
      console.error(err);
    }
  };

  // Функция для проверки статуса обработки
  const checkProcessingStatus = async (id) => {
    try {
      const response = await api.get(`/processing_status/${id}`);
      const data = response.data;

      if (data.status === 'Completed') {
        // Перенаправляем на страницу готового проекта
        navigate('/guest-project-ready', { state: { outputVideo: data.output_video, processingId: id } });
      } else if (data.status === 'Failed') {
        setError('Обработка не удалась. Пожалуйста, попробуйте снова.');
      } else {
        // Если обработка еще не завершена, проверяем снова через 5 секунд
        setTimeout(() => checkProcessingStatus(id), 5000);
      }
    } catch (err) {
      setError('Ошибка при проверке статуса обработки.');
      console.error(err);
    }
  };

  // Используем useRef для предотвращения двойного запуска useEffect
  const effectRan = useRef(false);

  useEffect(() => {
    console.log('useEffect called');

    if (effectRan.current) {
      // Если эффект уже запускался, выходим из функции
      return;
    }

    effectRan.current = true; // Отмечаем, что эффект был запущен

    if (videoFile) {
      uploadAndProcessVideo();
    } else if (youtubeUrl) {
      uploadAndProcessYoutubeVideo();
    } else {
      setError('Нет видео для обработки.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="video-editor">
      <div className="main-container-processing">
        <div className="processing-container">
          <div className="processing-spinner">
            <div className="loader"></div>
          </div>
        </div>
        <div className="loading-phrases">
          <p key={currentPhrase} className="loading-phrase">
            {phrases[currentPhrase]}
          </p>
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default GuestProcessingPage;
