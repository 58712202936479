import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import './VideoEditor.css';
import Sidebar from './sidebar/Sidebar';
import Header from './header/Header';
import AiPage from './ai_page/AiPage';
import CutPage from './cut/Cut';
import SubtitlesPage from './subtitles_page/SubtitlesPage';
import BrainrotPage from './brainrot_page/BrainrotPage';
import FacetrackingPage from './facetracking_page/FacetrackingPage';
import ProjectListPage from './project_list_page/ProjectListPage';
import MediaListPage from './media_list_page/MediaListPage';
import HelpPage from './help_page/HelpPage';
import ProcessingPage from './processing_page/ProcessingPage';
import ProjectReadyPage from './project_ready_page/ProjectReadyPage';
import EditVideoPage from './edit_video_page/EditVideoPage';
import HomePage from './home_page/HomePage';
import UserPage from './user_page/UserPage';
import LoginPage from './login_page/LoginPage';
import Commercial from './commercial/Commercial';
import PasswordReset from './password_change/password_change';
import UserContext, { UserProvider } from './UserContext';
import api from './api';
import Loader from './loader/Loader';
import ConfirmationModal from './confirmation_modal/ConfirmationModal'; // Импортируем компонент модального окна
import GuestProcessingPage from './guest_processing/GuestProcessingPage';
import GuestProjectReadyPage from './guest_ready/GuestProjectReadyPage';

api.defaults.withCredentials = true;

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(UserContext);

  if (loading) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

const MainView = () => {
  const navigate = useNavigate();
  const [processingId, setProcessingId] = useState(null);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isReadyToUpload, setIsReadyToUpload] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showModal, setShowModal] = useState(false); // Добавляем состояние для модального окна

  useEffect(() => {
    // Проверяем параметр email_confirmed в URL
    const urlParams = new URLSearchParams(window.location.search);
    const emailConfirmed = urlParams.get('email_confirmed');

    if (emailConfirmed === 'true') {
      setShowModal(true); // Показываем модальное окно, если email подтверждён
    }
  }, []);

  const handleFileChange = () => {
    const fileInput = document.getElementById('file-input');
    const file = fileInput.files[0];
    if (file || youtubeUrl) {
      setIsReadyToUpload(true);
    } else {
      setIsReadyToUpload(false);
    }
  };

  const handleYoutubeUrlChange = (e) => {
    setYoutubeUrl(e.target.value);
    const fileInput = document.getElementById('file-input');
    const file = fileInput.files[0];
    if (file || e.target.value) {
      setIsReadyToUpload(true);
    } else {
      setIsReadyToUpload(false);
    }
  };

   const handleUpload = async () => {
      setIsUploading(true);
      setError(null);
      setSuccessMessage('');
      const fileInput = document.getElementById('file-input');
      const file = fileInput.files[0];

      try {
        let response;

        if (file) {
          const formData = new FormData();
          formData.append('video', file);

          response = await api.post('/upload_video', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setProcessingId(response.data.processing_id);
          setSuccessMessage('Файл загружен, пожалуйста, выберите способ обработки');
        } else if (youtubeUrl) {
          response = await api.post('/upload_youtube_video', { youtube_url: youtubeUrl });
          setProcessingId(response.data.processing_id);
          setSuccessMessage('Видео с YouTube загружено, пожалуйста, выберите способ обработки');
        } else {
          setError('Пожалуйста, загрузите файл или введите URL YouTube.');
          setIsUploading(false);
          return;
        }
      } catch (error) {
        if (error.response) {
          console.error('Ошибка ответа от сервера:', error.response.data);
          if (error.response.status === 413) {
            setError(error.response.data.error || 'Файл слишком большой. Максимальный размер файла — 1 ГБ.');
          } else if (error.response.status === 403 && error.response.data.error === 'Email not confirmed') {
            setError('Перед началом обработки подтвердите, пожалуйста, почту.');
          } else {
            setError(error.response.data.error || 'Ошибка на сервере. Попробуйте снова.');
          }
        } else if (error.request) {
          console.error('Ошибка запроса:', error.request);
          setError('Не удалось получить ответ от сервера. Проверьте соединение и попробуйте снова.');
        } else {
          console.error('Ошибка при настройке запроса:', error.message);
          setError('Произошла ошибка при отправке запроса. Попробуйте снова.');
        }
      } finally {
        setIsUploading(false);
      }
    };



  const goToAiPage = () => {
    if (processingId) {
      navigate('/ai', { state: { processingId } });
    } else {
      alert('Пожалуйста, сначала загрузите файл.');
    }
  };

  const goToCutPage = () => {
    if (processingId) {
      navigate('/cut', { state: { processingId } });
    } else {
      alert('Пожалуйста, сначала загрузите файл.');
    }
  };

  const goToSubPage = () => {
    if (processingId) {
      navigate('/subtitles', { state: { processingId } });
    } else {
      alert('Пожалуйста, сначала загрузите файл.');
    }
  };

  const goToBrainrotPage = () => {
    if (processingId) {
      navigate('/brainrot', { state: { processingId } });
    } else {
      alert('Пожалуйста, сначала загрузите файл.');
    }
  };

  const goToFacetrackingPage = () => {
    if (processingId) {
      navigate('/facetracking', { state: { processingId } });
    } else {
      alert('Пожалуйста, сначала загрузите файл.');
    }
  };

  return (
    <div className="video-editor">
      <Sidebar />
      <div className="main-container">
        <Header />
        {isUploading && (
          <div className="loader-overlay-main">
            <Loader />
          </div>
        )}
        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
        <div className="upload-container">
          <input type="file" id="file-input" className="upload-file-input" onChange={handleFileChange} />
          <label htmlFor="file-input" className="upload-file-label">Выберите файл</label>
          <input
            type="text"
            placeholder="Вставьте URL сюда"
            className="upload-input"
            value={youtubeUrl}
            onChange={handleYoutubeUrlChange}
          />
          <button
            className={`upload-btn ${isReadyToUpload ? '' : 'disabled'}`}
            onClick={handleUpload}
            disabled={!isReadyToUpload || isUploading}
          >
            {isUploading ? 'Загрузка...' : 'Загрузить'}
          </button>
        </div>
        <main className="main-content">
          <section className="module" onClick={goToAiPage}>
            <h2>AI</h2>
            <p>Создает короткое видео из вашего источника с помощью ИИ</p>
            <button className="feature-btn ai-btn">AI +</button>
          </section>
          <section className="module" onClick={goToCutPage}>
            <h2>Cut</h2>
            <p>Разрезает ваше видео на короткие клипы, чтобы вы могли выбрать понравившийся</p>
            <button className="feature-btn cut-btn">Cut +</button>
          </section>
          <section className="module" onClick={goToSubPage}>
            <h2>Subtitles</h2>
            <p>Добавляет настраиваемые субтитры к вашему видео</p>
            <button className="feature-btn subtitles-btn">Subtitles +</button>
          </section>
          <section className="module" onClick={goToBrainrotPage}>
            <h2>Brainrot</h2>
            <p>Создает видео с разделенным экраном для привлечения внимания зрителей</p>
            <button className="feature-btn subtitles-btn">Brainrot +</button>
          </section>
          <section className="module" onClick={goToFacetrackingPage}>
            <h2>Facetracking</h2>
            <p>Обрезает ваше видео для вертикального формата, сохраняя интересные части и выделяя лицо  </p>
            <button className="feature-btn subtitles-btn">Facetracking +</button>
          </section>
        </main>
      </div>
      {/* Добавляем модальное окно */}
      <ConfirmationModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<ProtectedRoute><MainView /></ProtectedRoute>} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/commercial" element={<Commercial />} />
          <Route path="/ai" element={<ProtectedRoute><AiPage /></ProtectedRoute>} />
          <Route path="/cut" element={<ProtectedRoute><CutPage /></ProtectedRoute>} />
          <Route path="/subtitles" element={<ProtectedRoute><SubtitlesPage /></ProtectedRoute>} />
          <Route path="/brainrot" element={<ProtectedRoute><BrainrotPage /></ProtectedRoute>} />
          <Route path="/facetracking" element={<ProtectedRoute><FacetrackingPage /></ProtectedRoute>} />
          <Route path="/project-list" element={<ProtectedRoute><ProjectListPage /></ProtectedRoute>} />
          <Route path="/media-list" element={<ProtectedRoute><MediaListPage /></ProtectedRoute>} />
          <Route path="/help" element={<ProtectedRoute><HelpPage /></ProtectedRoute>} />
          <Route path="/processing" element={<ProtectedRoute><ProcessingPage /></ProtectedRoute>} />
          <Route path="/project-ready" element={<ProtectedRoute><ProjectReadyPage /></ProtectedRoute>} />
          <Route path="/edit-video" element={<ProtectedRoute><EditVideoPage /></ProtectedRoute>} />
          <Route path="/user" element={<ProtectedRoute><UserPage /></ProtectedRoute>} />
          <Route path="/password_reset" element={<PasswordReset />} />
          <Route path="/guest-processing" element={<GuestProcessingPage />} />
          <Route path="/guest-project-ready" element={<GuestProjectReadyPage />} />

        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;