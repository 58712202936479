import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ProcessingPage.css';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import tutorialImage from '../anime-poster.png'; // Replace with the correct path

const ProcessingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { outputVideo, processingId, error: passedError } = location.state || {};
  const [currentPhrase, setCurrentPhrase] = useState(0);
  const [error, setError] = useState(passedError || null);

  const phrases = [
    "Taking a look at your video",
    "Gave your video to our workers (they are manually cutting it)",
    "Processing... Please wait",
    "Almost done... Hang tight!",
    "Still working, man...",
    "Wait a second pls"
  ];

  // Update phrase every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhrase((prevPhrase) => (prevPhrase + 1) % phrases.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // Показываем ошибку сразу после загрузки страницы, если она есть
  useEffect(() => {
    if (passedError) {
      setError(passedError);
    }
  }, [passedError]);

  // Redirect to project-ready page when processing is complete
  useEffect(() => {
    if (outputVideo) {
      try {
        navigate('/project-ready', { state: { outputVideo, processingId } });
      } catch (err) {
        setError('Упс, что-то пошло не так. Попробуйте повторить попытку.');
        console.error('Error while navigating:', err);
      }
    }
  }, [outputVideo, processingId, navigate]);

  return (
    <div className="video-editor">
      <Sidebar />
      <div className="main-container-processing">
        <Header />
        <div className="processing-container">
          <div className="tutorial">
          </div>
          <div className="processing-spinner">
            <div className="loader"></div>
          </div>
        </div>
        <div className="loading-phrases">
          <p key={currentPhrase} className="loading-phrase">{phrases[currentPhrase]}</p>
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default ProcessingPage;
