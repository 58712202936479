// Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import homeIcon from '../icons/home.png';
import newProjectIcon from '../icons/new_project.png';
import projectListIcon from '../icons/project_list.png';
import mediaListIcon from '../icons/media_list.png';
import helpIcon from '../icons/help.png';
import logoIcon from '../logo_icon.png';
import './Sidebar.css';

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <a className='logo_text'>
        <img src={logoIcon} alt="Logo" className="logo_img" />
        KlipAI
      </a>
      <ul>
        <li><Link to="/home" className="sidebar-link"><img src={homeIcon} alt="Home" /> Home</Link></li>
        <li><Link to="/" className="sidebar-link"><img src={newProjectIcon} alt="New Project" /> New project</Link></li>
        <li><Link to="/project-list" className="sidebar-link"><img src={projectListIcon} alt="Project List" /> Project list</Link></li>
        <li><Link to="/media-list" className="sidebar-link"><img src={mediaListIcon} alt="Media List" /> Media list</Link></li>
        <li><Link to="/help" className="sidebar-link"><img src={helpIcon} alt="Help" /> Help</Link></li>
      </ul>
    </aside>
  );
};

export default Sidebar;
