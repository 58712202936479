// HomePage.js

import React, { useContext, useState } from 'react';
import UserContext from '../UserContext';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import vkIcon from '../icons/vk.png';
import youtubeIcon from '../icons/youtube.png';
import tiktokIcon from '../icons/tiktok.png';
import instagramIcon from '../icons/instagram.png';
import api from '../api';

const HomePage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [videoFile, setVideoFile] = useState(null);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [error, setError] = useState(null);

  const handleUploadVideo = async () => {
    setError(null);

    if (!videoFile) {
      setError('Пожалуйста, выберите видео для загрузки.');
      return;
    }

    // Сохраняем текущий файл для передачи на страницу обработки
    const fileToUpload = videoFile;

    // Сбрасываем состояние видеофайла, чтобы предотвратить повторную загрузку
    setVideoFile(null);

    // Переходим на страницу обработки и передаем файл
    navigate('/guest-processing', { state: { videoFile: fileToUpload } });
  };

  const handleUploadYoutubeVideo = async () => {
    console.log('handleUploadYoutubeVideo called')
    setError(null);

    if (!youtubeUrl) {
      setError('Пожалуйста, введите URL YouTube видео.');
      return;
    }

    // Переходим на страницу обработки и передаем URL
    navigate('/guest-processing', { state: { youtubeUrl } });
  };

  const handleStartUsingClick = () => {
    if (user) {
      navigate('/');
    } else {
      navigate('/login');
    }
  };

  const handleCommercialClick = () => {
    navigate('/commercial');
  };

  return (
    <div className="home-container">
      <header className="header_landing">
        <div className="logo">
          <h1>KlipAI</h1>
        </div>
        <nav className="nav-links">
          <a href="#about">About</a>
          <a href="#share">Share Video</a>
          <a href="#pricing">Pricing</a>
          <a href="#contact">Contact Us</a>
        </nav>
      </header>

      <section className="intro-section">
        <h1>Introducing a fully automated AI tool for content creators</h1>
        <div className="intro-actions">
          {user ? (
            <div>
              <button className="action-btn" onClick={() => navigate('/')}>Go to App</button>
            </div>
          ) : (
            <div className="guest-actions">
              <div className="upload-section">
              <input
                type="file"
                accept="video/*"
                onChange={(e) => setVideoFile(e.target.files[0])}
                id="file-input"
                className="file-input"
              />
              <label htmlFor="file-input" className="file-label">Choose File</label>
              <button onClick={handleUploadVideo} className="action-btn">Upload and Process Video</button>
            </div>
              <div className="youtube-section">
                <input
                  type="text"
                  value={youtubeUrl}
                  onChange={(e) => setYoutubeUrl(e.target.value)}
                  placeholder="Paste in YouTube URL here"
                  className="url-input"
                />
                <button onClick={handleUploadYoutubeVideo} className="action-btn">Process YouTube Video</button>
              </div>
            </div>
          )}
        </div>
        {error && <p className="error">{error}</p>}
      </section>

      <section id="about" className="about-section">
        <h2>About</h2>
        <p>Our instrument helps you to:</p>
        <div className="about-features">
          <div className="feature">
            <h3>AI Video Editing</h3>
            <p>Automatically edit your videos completely by adding subtitles, emojis, face tracking, and more, all customizable to your needs.</p>
          </div>
          <div className="feature">
            <h3>Video Cut</h3>
            <p>Smartly cut your videos by context with an optional face tracking feature that you can turn off as needed.</p>
          </div>
          <div className="feature">
            <h3>AI Subtitles</h3>
            <p>Add intelligent subtitles to your videos, including emojis with subtitles, all automatically applied. Customize with a variety of options to suit your preferences.</p>
          </div>
          <div className="feature">
            <h3>Brainrot</h3>
            <p>Generate split screen videos to grasp the viewer's attention.</p>
          </div>
        </div>
      </section>

      <section id="share" className="share-video-section">
        <h2>Share Your Video</h2>
        <div className="social-icons">
          <img src={tiktokIcon} alt="TikTok" />
          <img src={instagramIcon} alt="Instagram" />
          <img src={youtubeIcon} alt="YouTube" />
        </div>
      </section>

      <section id="pricing" className="prices-section">
        <h2>Pricing</h2>
        <div className="price-plans">
          <div className="price-plan free">
            <div className="plan-header">
              <h3>Free</h3>
              <p>0 rub / month</p>
            </div>
            <ul>
              <li>Watermark</li>
              <li>Videos saved for 1 day</li>
              <li>60 minutes of upload</li>
              <li>Basic support</li>
            </ul>
          </div>

          <div className="price-plan pro">
            <div className="plan-header">
              <h3>Pro</h3>
              <p>390 rub / month</p>
            </div>
            <ul>
              <li>No watermark</li>
              <li>Videos saved for 30 days</li>
              <li>350 minutes of upload</li>
              <li>Basic support</li>
              <li>Chat support</li>
              <li>Analytics in chat</li>
            </ul>
          </div>

          <div className="price-plan legend">
            <div className="plan-header">
              <h3>Legend</h3>
              <p>850 rub / month</p>
            </div>
            <ul>
              <li>No watermark</li>
              <li>Videos saved for 70 days</li>
              <li>900 minutes of upload</li>
              <li>Basic support</li>
              <li>Full technical support</li>
              <li>Chat support</li>
              <li>Own analytics</li>
            </ul>
          </div>
        </div>
        <p className="subscription-info">
          Мы не используем автоплатежи — ничего не будет списано с вашей карты без вашего подтверждения. Если лимит истек, его можно легко продлить вручную.
          <br />
          * Лимит действителен 30 дней с момента оплаты.
        </p>
        <button className="start-using-btn" onClick={handleStartUsingClick}>Start using</button>
      </section>

      <footer id="contact" className="footer-section">
        <div className="footer-columns">
          <div className="footer-column">
            <p>Social media</p>
            <div className="social-icons">
              <img src={vkIcon} alt="VK" />
              <img src={tiktokIcon} alt="TikTok" />
              <img src={youtubeIcon} alt="YouTube" />
              <img src={instagramIcon} alt="Instagram" />
            </div>
          </div>
          <div className="footer-column">
            <p>Organization</p>
            <p>ООО "СтимСтокс"</p>
            <p>Генеральный директор: Пономарев Артем Викторович</p>
            <p>ИНН: 9729330204</p>
            <p>БИК: 044525092</p>
            <p>КПП: 772901001</p>
            <p>Расчетный счёт: 40702810470010362170</p>
          </div>
          <div className="footer-column">
            <p>Contact us</p>
            <p>SEO: visulaboratory@gmail.com</p>
          </div>
          <div className="footer-column">
            <p onClick={handleCommercialClick} className="commercial-link">Договор оферты</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
