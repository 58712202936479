// src/pages/Commercial.js
import React from 'react';
import './Commercial.css';

const Commercial = () => {
  return (
    <div className="commercial-container">
      <h1 className="title-commercial">ДОГОВОР-ОФЕРТА</h1>
      <div className="content-commercial">
        <h2 className="section-title-commercial">1. Общие положения</h2>
        <p className="paragraph-commercial">
          1.1. Настоящий Договор является публичной офертой ООО "СТИМСТОКС" (далее - "Исполнитель"), предлагающей услуги по подписке на программный продукт "KlipAI", для лиц (далее – "Заказчик"), принимающих (акцептующих) это предложение.
        </p>
        <p className="paragraph-commercial">
          1.2. Акцепт настоящей оферты равносилен заключению договора на условиях, изложенных в оферте, согласно статьям 437 и 438 Гражданского кодекса РФ.
        </p>
        <p className="paragraph-commercial">
          1.3. Акцепт оферты считается осуществленным при оплате услуг "KlipAI" через указанные способы на официальном сайте Исполнителя.
        </p>

        <h2 className="section-title-commercial">2. Предмет договора</h2>
        <p className="paragraph-commercial">
          2.1. Исполнитель предоставляет Заказчику услуги по подписке на программный продукт "KllipAI", который включает автоматическую или полу-автоматическую обработку видео.
        </p>
        <p className="paragraph-commercial">
          2.2. Подробное описание услуг, их стоимость и условия использования указаны на официальном сайте Исполнителя.
        </p>

        <h2 className="section-title-commercial">3. Оплата Услуг</h2>
        <p className="paragraph-commercial">
          3.1. Стоимость услуг определяется в соответствии с действующими ценами, указанными на официальном сайте Исполнителя.
        </p>
        <p className="paragraph-commercial">
          3.2. Способы оплаты услуг указаны при оформлении платежа на сайте Исполнителя.
        </p>

        <h2 className="section-title-commercial">4. Интеллектуальная собственность</h2>
        <p className="paragraph-commercial">
          4.1. Все материалы, связанные с "KlipAI", включая тексты, изображения и код, являются собственностью Исполнителя.
        </p>

        <h2 className="section-title-commercial">5. Особые условия и ответственность сторон</h2>
        <p className="paragraph-commercial">
          5.1. Исполнитель несет ответственность за своевременность предоставляемых услуг при выполнении Заказчиком установленных требований и правил, размещенных на официальном сайте Исполнителя.
        </p>
        <p className="paragraph-commercial">
          5.2. Исполнитель освобождается от ответственности за нарушение условий Договора, если такое нарушение вызвано действием обстоятельств непреодолимой силы (форс-мажор), включая: действия органов государственной власти, пожар, наводнение, землетрясение, другие стихийные действия, отсутствие электроэнергии, забастовки, гражданские волнения, беспорядки, любые иные обстоятельства, не ограничиваясь перечисленным, которые могут повлиять на выполнение Исполнителем Договора.
        </p>
        <p className="paragraph-commercial">
          5.3. Исполнитель не несет ответственности за качество каналов связи общего пользования или служб, предоставляющих доступ Заказчика к его услугам.
        </p>

        <h2 className="section-title-commercial">6. Конфиденциальность и защита персональной информации</h2>
        <p className="paragraph-commercial">
          6.1. Исполнитель обязуется не разглашать полученную от Заказчика информацию без его согласия, за исключением случаев, предусмотренных законодательством РФ.
        </p>

        <h2 className="section-title-commercial">7. Порядок рассмотрения претензий и споров</h2>
        <p className="paragraph-commercial">
          7.1. Претензии Заказчика по предоставляемым услугам принимаются Исполнителем к рассмотрению по электронной почте в течение 2 (рабочих) дней с момента возникновения спорной ситуации.
        </p>
        <p className="paragraph-commercial">
          7.2. При рассмотрении спорных ситуаций Исполнитель вправе запросить у Заказчика всю интересующую документацию относительно рассматриваемого мероприятия. В случае непредоставления Заказчиком документов в течение 1 рабочего дня после дня требования, претензия рассмотрению Исполнителем не подлежит.
        </p>
        <p className="paragraph-commercial">
          7.3. Исполнитель и Заказчик, принимая во внимания характер оказываемой услуги, обязуются в случае возникновения споров и разногласий, связанных с оказанием услуг, применять досудебный порядок урегулирования спора. В случае невозможности урегулирования спора в досудебном порядке стороны вправе обратиться в суд.
        </p>

        <h2 className="section-title-commercial">8. Прочие условия</h2>
        <p className="paragraph-commercial">
          8.1. Все вопросы, не урегулированные настоящим Договором, регулируются в соответствии с законодательством РФ.
        </p>
        <p className="paragraph-commercial">
          8.2. Данный Договор вступает в силу с момента акцепта Оферты Заказчиком и действует до момента отзыва Оферты Исполнителем.
        </p>

        <h2 className="section-title-commercial">9. Реквизиты Исполнителя</h2>
        <p className="paragraph-commercial">
          ООО "СТИМСТОКС" <br/>
          Юридический адрес: 119415, город Москва, Ленинский пр-кт, д. 106 к. 1 <br/>
          ИНН: 9729330204 <br/>
          КПП: 772901001 <br/>
          ОГРН: 1227700579809 <br/>
          Расчетный счёт: 40702810470010362170 <br/>
          Банк: МОСКОВСКИЙ ФИЛИАЛ АО КБ "МОДУЛЬБАНК" <br/>
          БИК: 044525092 <br/>
          Генеральный директор: Пономарев Артем Викторович
        </p>

        <h2 className="section-title-commercial">10. Контактные данные</h2>
        <p className="paragraph-commercial">
          Почта: visulaboratory@gmail.com <br/>
          Горячая линия: +79153881323
        </p>
      </div>
    </div>
  );
};

export default Commercial;
