import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserPage.css';
import defaultAvatar from '../icons/avatar.png';
import logo from '../logo_icon.png';
import UserContext from '../UserContext';
import api from '../api';
import Loader from '../loader/Loader';
import SubscriptionModal from '../subscription_modal/SubscriptionModal';

api.defaults.withCredentials = true;

const UserPage = () => {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    userId: '',
    password: '*********',
    subscription: 'Premium',
    subscriptionId: 2,  // Add subscriptionId to track the current subscription ID
    videosUsed: 10,
    videosTotal: 15,
    nextBillingDate: '2024-07-01',
    avatarUrl: '',
    actions: {}
  });
  const [isEditing, setIsEditing] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newAvatar, setNewAvatar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useContext(UserContext);
  const fileInputRef = useRef(null);

  const subscriptions = [
    { id: 1, name: 'Free', price: '0 rub / month', products: { Content: 60, }, note: 'Includes watermark', privileges: ['Basic support'], features: { 'Chat support': false} },
    { id: 2, name: 'Pro', price: '390 rub / month', products: { Content: 350 }, privileges: ['Basic support', 'Analytics in chat'], features: { 'Chat support': true, 'Own analytic': false } },
    { id: 3, name: 'Legend', price: '850 rub / month', products: { Content: 900 }, privileges: ['Full technical support', 'Chat support', 'Own analytic'], features: { 'Chat support': true} },
  ];

  useEffect(() => {
    // Функция проверки и обновления подписки
    const checkSubscriptionPayment = () => {
      api.post('/check_subscription_payment')
        .then(response => {
          console.log('Subscription status checked:', response.data);
          // Обновляем данные пользователя в зависимости от ответа
          if (response.data.subscription) {
            setUserData(prevData => ({
              ...prevData,
              subscription: response.data.subscription,
              subscriptionId: subscriptions.find(sub => sub.name === response.data.subscription).id,
            }));
          }
        })
        .catch(error => {
          console.error('Error checking subscription status:', error);
        });
    };

    // Загрузка данных пользователя
    api.get('/user')
      .then(response => {
        setUserData({
          ...response.data,
          subscriptionId: subscriptions.find(sub => sub.name === response.data.subscription).id,  // Set subscriptionId based on current subscription
        });
        setNewEmail(response.data.email);
        setNewFirstName(response.data.firstName);
        setNewLastName(response.data.lastName);
        checkSubscriptionPayment(); // Проверка статуса подписки
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate]);

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('avatar', file);

      api.post('/upload_avatar', formData)
        .then(response => {
          setUserData(prevData => ({
            ...prevData,
            avatarUrl: response.data.avatarUrl,
          }));
        })
        .catch(error => {
          console.error('Error uploading avatar:', error);
        });
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleSubscriptionChange = (subscriptionTypeId) => {
    const selectedSubscription = subscriptions.find(sub => sub.id === subscriptionTypeId);
    setUserData(prevData => ({
      ...prevData,
      subscription: selectedSubscription.name,
      subscriptionId: selectedSubscription.id,  // Update the subscription ID
      videosTotal: selectedSubscription.products.AI, // Assuming the AI amount represents the total videos allowed
      nextBillingDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // Set next billing date to 30 days from now
    }));
  };

  const calculateTotalMinutes = (actions) => {
    return Object.values(actions).reduce((total, amount) => total + amount, 0);
  };

  const totalMinutes = calculateTotalMinutes(userData.actions);

  if (loading) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  return (
    <div className="user-container-user-page">
      <header className="user-header-user-page">
        <div className="logo-user-page" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
          <img src={logo} alt="Logo" />
          <h1>KlipAI</h1>
        </div>
      </header>
      <div className="user-avatar-container-user-page">
        <img
          src={userData.avatarUrl ? `${api.defaults.baseURL}${userData.avatarUrl}?token=${localStorage.getItem('token')}` : defaultAvatar}
          alt="User Avatar"
          className="user-avatar-user-page"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultAvatar;
          }}
          style={{ zIndex: 10 }} // Ensure avatar is above other content
        />
        <input
          type="file"
          onChange={handleAvatarChange}
          accept="image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <button className="edit-btn-user-page" onClick={handleEditClick}>Edit</button>
      </div>

      <div className="user-content-user-page">
        <div className="user-info-section-user-page">
          <div className="user-details-user-page">
            <div className="user-detail-row-user-page">
              <div className="user-detail-label-user-page">First Name</div>
              <div className="user-detail-value-user-page">
                {isEditing ? (
                  <input
                    type="text"
                    value={newFirstName}
                    onChange={(e) => setNewFirstName(e.target.value)}
                    className="edit-input-user-page"
                  />
                ) : (
                  userData.firstName
                )}
              </div>
            </div>
            <div className="user-detail-row-user-page">
              <div className="user-detail-label-user-page">Last Name</div>
              <div className="user-detail-value-user-page">
                {isEditing ? (
                  <input
                    type="text"
                    value={newLastName}
                    onChange={(e) => setNewLastName(e.target.value)}
                    className="edit-input-user-page"
                  />
                ) : (
                  userData.lastName
                )}
              </div>
            </div>
            <div className="user-detail-row-user-page">
              <div className="user-detail-label-user-page">User ID</div>
              <div className="user-detail-value-user-page">{userData.userId}</div>
            </div>
            <div className="user-detail-row-user-page">
              <div className="user-detail-label-user-page">Email</div>
              <div className="user-detail-value-user-page">
                {isEditing ? (
                  <input
                    type="email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    className="edit-input-user-page"
                  />
                ) : (
                  userData.email
                )}
              </div>
            </div>
            <div className="user-detail-row-user-page">
              <div className="user-detail-label-user-page">Password</div>
              <div className="user-detail-value-user-page">
                {isEditing ? (
                  <input
                    type="password"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="edit-input-user-page"
                  />
                ) : (
                  '*********'
                )}
              </div>
            </div>
          </div>
          <div className="user-actions-left-user-page">
            <button className="logout-btn-user-page" onClick={handleLogout}>Log Out</button>
            <button className="delete-account-btn-user-page">Delete Account</button>
          </div>
        </div>
        <div className="subscription-info-section-user-page">
          <div className="user-detail-row-user-page">
            <div className="user-detail-label-user-page">Subscription</div>
            <div className="user-detail-value-user-page">
              {userData.subscription}
            </div>
          </div>
          <div className="user-detail-row-user-page">
            <div className="user-detail-label-user-page">Videos Created</div>
            <div className="user-detail-value-user-page">
              {Object.entries(userData.actions).map(([actionType, amount]) => {
                const usagePercentage = (amount / userData.videosTotal) * 100;
                return (
                  <div key={actionType}>
                    <div className="progress-container-user-page">
                      <div className="progress-bar-user-page">
                        <div
                          className="progress-user-page"
                          style={{ width: `${usagePercentage}%` }}
                        ></div>
                      </div>
                      <span className="progress-info-user-page">
                        {amount.toFixed(1)} / {userData.videosTotal} {actionType} videos created
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="user-detail-row-user-page">
            <div className="user-detail-label-user-page">Total Upload Minutes</div>
            <div className="user-detail-value-user-page">
              {totalMinutes.toFixed(1)} / {userData.videosTotal} minutes
            </div>
          </div>
          <div className="user-detail-row-user-page">
            <div className="user-detail-label-user-page">Next Billing Date</div>
            <div className="user-detail-value-user-page">
              {userData.nextBillingDate}
            </div>
          </div>
          <div className="user-actions-right-user-page">
            <button className="change-subscription-btn-user-page" onClick={() => setIsModalOpen(true)}>Change Subscription</button>
            <button className="unsubscribe-btn-user-page">Unsubscribe</button>
          </div>
        </div>
      </div>
      <SubscriptionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSelect={handleSubscriptionChange}
        userId={userData.userId}
        subscriptions={subscriptions}
        currentSubscriptionId={userData.subscriptionId}  // Pass the current subscription ID to the modal
      />
    </div>
  );
};

export default UserPage;
