import React, { useState, useEffect, useRef } from 'react';
import './EditVideoPage.css';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import trashIcon from '../icons/trash.png';

import video1 from '../videos/jake.mp4';
import video2 from '../videos/silance.mp4';
import video3 from '../videos/animation.mp4';

const EditVideoPage = () => {
  const [clips, setClips] = useState([
    { id: 1, title: '[Vertical] Who are these videos for?', duration: '01:01', src: video1, transcript: 'Transcript for video 1...', active: true },
    { id: 2, title: '[Portrait] Who are these videos for?', duration: '02:24', src: video2, transcript: 'Transcript for video 2...', active: true },
    { id: 3, title: '[Square] Who are these videos for?', duration: '00:21', src: video3, transcript: 'Transcript for video 3...', active: true },
  ]);

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const videoRefs = useRef(clips.map(() => React.createRef()));
  const [currentClipIndex, setCurrentClipIndex] = useState(0);

  useEffect(() => {
    videoRefs.current = clips.map(() => React.createRef());
  }, [clips]);

  useEffect(() => {
    const currentVideoRef = videoRefs.current[currentClipIndex];
    if (currentVideoRef && currentVideoRef.current) {
      currentVideoRef.current.play();
      currentVideoRef.current.onended = () => {
        if (currentClipIndex < clips.length - 1) {
          setCurrentClipIndex(currentClipIndex + 1);
        }
      };
    }
  }, [currentClipIndex]);

  const handleDelete = (id) => {
    const updatedClips = clips.map(clip => clip.id === id ? { ...clip, active: false } : clip);
    setClips(updatedClips);

    if (clips[currentClipIndex].id === id) {
      const nextClipIndex = updatedClips.findIndex((clip, index) => clip.active && index > currentClipIndex);
      const previousClipIndex = updatedClips.findIndex((clip, index) => clip.active && index < currentClipIndex);

      if (nextClipIndex !== -1) {
        setCurrentClipIndex(nextClipIndex);
      } else if (previousClipIndex !== -1) {
        setCurrentClipIndex(previousClipIndex);
      } else {
        setCurrentClipIndex(0);
      }
    }
  };

  const handleRestore = (id) => {
    setClips(clips.map(clip => clip.id === id ? { ...clip, active: true } : clip));
  };

  const handleClipClick = (index) => {
    setCurrentClipIndex(index);
  };

  const handleReorder = (fromIndex, toIndex) => {
    const updatedClips = Array.from(clips);
    const [movedClip] = updatedClips.splice(fromIndex, 1);
    updatedClips.splice(toIndex, 0, movedClip);
    setClips(updatedClips);
  };

  const toggleSettingsVisibility = () => {
    setIsSettingsVisible(!isSettingsVisible);
  };

  return (
    <div className="video-editor">
      <Sidebar />
      <div className="main-container-edit">
        <Header />
        <div className="edit-container">
          <div className="clip-list">
            {clips.map((clip, index) => (
              <div
                key={clip.id}
                className={`clip-item ${!clip.active ? 'inactive' : ''}`}
                onClick={() => handleClipClick(index)}
              >
                <div className="clip-info">
                  <p>{clip.title}</p>
                  <p>{clip.duration}</p>
                  <p>{clip.transcript}</p>
                </div>
                <video
                  ref={videoRefs.current[index]}
                  src={clip.src}
                  onClick={() => handleClipClick(index)}
                />
                <div className="clip-actions">
                  <img
                    src={trashIcon}
                    alt="Delete"
                    className="delete-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(clip.id);
                    }}
                  />
                  <button onClick={(e) => {
                    e.stopPropagation();
                    handleReorder(index, index - 1);
                  }} disabled={index === 0}>↑</button>
                  <button onClick={(e) => {
                    e.stopPropagation();
                    handleReorder(index, index + 1);
                  }} disabled={index === clips.length - 1}>↓</button>
                </div>
              </div>
            ))}
            {clips.length === 0 && <p>Add at least one video</p>}
          </div>
          {clips.length > 0 && clips[currentClipIndex] && clips[currentClipIndex].active && (
            <div className="main-video-container">
              <h3>{clips[currentClipIndex].title}</h3>
              <video
                ref={videoRefs.current[currentClipIndex]}
                src={clips[currentClipIndex].src}
                controls
                className="main-video"
              />
            </div>
          )}
          <div className="settings-container">
            <h4 onClick={toggleSettingsVisibility} className="settings-toggle">
              {isSettingsVisible ? 'Hide Subtitle Settings' : 'Change subtitles'}
            </h4>
            {isSettingsVisible && (
              <>
                <div className="settings-group">
                  <label>Font</label>
                  <select>
                    <option>Arial</option>
                    <option>Verdana</option>
                    <option>Helvetica</option>
                  </select>
                </div>
                <div className="settings-group">
                  <label>Font Color</label>
                  <input type="color" />
                </div>
                <div className="settings-group">
                  <label>Background Color</label>
                  <input type="color" />
                </div>
                <div className="settings-group">
                  <label>Auto Emoji</label>
                  <input type="checkbox" />
                </div>
                <div className="settings-group">
                  <label>Highlight Words</label>
                  <input type="checkbox" />
                </div>
                <div className="settings-group">
                  <label>Subtitle Background Color</label>
                  <input type="color" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVideoPage;
