import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProjectListPage.css';
import '../VideoEditor.css';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import trashIcon from '../icons/trash.png';
import downloadIcon from '../icons/save.png';
import ellipsisIcon from '../icons/ellipsis.png'; // Импортируем иконку с тремя точками
import api from '../api';
import UserContext from '../UserContext';
import Loader from '../loader/Loader';

api.defaults.withCredentials = true;

const ProjectListPage = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [editingVideoId, setEditingVideoId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [deleteType, setDeleteType] = useState(null); // 'project'
  const { user, loading } = useContext(UserContext);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [openMenuVideoId, setOpenMenuVideoId] = useState(null); // Новое состояние для открытого меню
  const navigate = useNavigate();

  const cardColors = [
    'card-1-project',
    'card-2-project',
    'card-3-project',
    'card-4-project',
    'card-5-project',
  ];

  // Создаем объекты ref для хранения ссылок на элементы
  const nameRefs = useRef({});
  const descriptionRefs = useRef({});
  const [originalName, setOriginalName] = useState('');
  const [originalDescription, setOriginalDescription] = useState('');

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    } else if (user) {
      const fetchProjects = async () => {
        try {
          const projectsResponse = await api.get(`/user_projects/${user.userId}`);
          const token = localStorage.getItem('token');
          const projectsWithToken = projectsResponse.data.map((project, index) => ({
            ...project,
            colorClass: cardColors[index % cardColors.length],
            videos: project.videos.map((video) => ({
              ...video,
              url: `${video.url}?token=${token}`,
            })),
          }));
          setProjects(projectsWithToken);
        } catch (error) {
          console.error('Error fetching projects:', error);
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
        } finally {
          setProjectsLoading(false);
        }
      };
      fetchProjects();
    }
  }, [user, loading, navigate]);

  const handleProjectClick = (project) => {
    setSelectedProjectId(project.project_id);
    setSelectedProject(project);
    setSelectedVideoId(null);
    setEditingVideoId(null);
  };

  const handleProjectDelete = async (projectId) => {
    try {
      await api.delete(`/delete_project/${projectId}`);
      setProjects(projects.filter((project) => project.project_id !== projectId));
      setSelectedProject(null);
      setSelectedProjectId(null);
    } catch (error) {
      console.error('Error deleting project:', error);
    }
    setShowModal(false);
  };

  const openModal = (id, type) => {
    setDeleteType(type);
    if (type === 'project') {
      setProjectToDelete(id);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setProjectToDelete(null);
    setDeleteType(null);
  };

  const handleBackToProjects = () => {
    setSelectedProject(null);
    setSelectedProjectId(null);
    setSelectedVideoId(null);
    setEditingVideoId(null);
  };

  const handleEditClick = (e, videoId) => {
    e.stopPropagation();
    if (editingVideoId === videoId) {
      // Сохраняем изменения
      handleSave(videoId);
    } else {
      // Начинаем редактирование
      setEditingVideoId(videoId);
      setSelectedVideoId(null);
      const video = selectedProject.videos.find((video) => video.id === videoId);
      setOriginalName(video.name || '');
      setOriginalDescription(video.description || '');

      // Устанавливаем фокус на название и перемещаем курсор в конец
      setTimeout(() => {
        if (nameRefs.current[videoId]) {
          const el = nameRefs.current[videoId];
          el.focus();

          // Перемещаем курсор в конец
          const range = document.createRange();
          range.selectNodeContents(el);
          range.collapse(false);
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }, 0);
    }
    setOpenMenuVideoId(null); // Закрываем меню
  };

  const handleSave = async (videoId) => {
    const nameContent = nameRefs.current[videoId]?.textContent || '';
    const descriptionContent = descriptionRefs.current[videoId]?.textContent || '';

    try {
      await api.put(`/update_video/${videoId}`, {
        name: nameContent,
        description: descriptionContent,
      });
      // Обновляем состояние
      setSelectedProject((prevProject) => ({
        ...prevProject,
        videos: prevProject.videos.map((video) =>
          video.id === videoId
            ? { ...video, name: nameContent, description: descriptionContent }
            : video
        ),
      }));
    } catch (error) {
      console.error('Error saving video:', error);
    } finally {
      setEditingVideoId(null);
    }
  };

  const handleCancelEdit = () => {
    if (nameRefs.current[editingVideoId]) {
      nameRefs.current[editingVideoId].textContent = originalName;
    }
    if (descriptionRefs.current[editingVideoId]) {
      descriptionRefs.current[editingVideoId].textContent = originalDescription;
    }
    setEditingVideoId(null);
  };

  // Добавляем эффект для закрытия видео и отмены редактирования при клике вне карточки
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectedVideoId !== null) {
        const cardElement = document.querySelector(
          `.card-project[data-id="${selectedVideoId}"]`
        );
        if (cardElement && !cardElement.contains(event.target)) {
          setSelectedVideoId(null);
        }
      }
      if (editingVideoId !== null) {
        const editingElement = document.querySelector(
          `.card-project[data-id="${editingVideoId}"]`
        );
        if (editingElement && !editingElement.contains(event.target)) {
          handleCancelEdit();
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectedVideoId, editingVideoId]);

  // Добавляем функцию для обработки клика по кнопке с тремя точками
  const handleEllipsisClick = (videoId) => {
    setOpenMenuVideoId(videoId === openMenuVideoId ? null : videoId);
  };

  // Эффект для закрытия меню при клике вне его области
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (openMenuVideoId !== null) {
        const menuElement = document.querySelector(`.options-menu[data-id="${openMenuVideoId}"]`);
        if (menuElement && !menuElement.contains(event.target)) {
          setOpenMenuVideoId(null);
        }
      }
    };

    document.addEventListener('click', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, [openMenuVideoId]);

  // Функция для скачивания видео
  const handleDownload = async (e, videoUrl, videoName) => {
    e.stopPropagation();
    try {
      const response = await fetch(videoUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = videoName || 'video.mp4';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading video:', error);
    }
  };

  return (
    <div className="video-editor">
      <Sidebar />
      <div className="main-container-projects">
        <Header />
        {projectsLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : selectedProject ? (
          <div className="project-videos">
            <button className="back-button" onClick={handleBackToProjects}>
              Назад к проектам
            </button>
            <h2 className="project-title">{selectedProject.name}</h2>
            <p className="project-description">{selectedProject.description}</p>
            <p className="project-upload-date">
              {new Date(selectedProject.upload_date).toLocaleDateString()}
            </p>
            <div className="cards-project">
              {selectedProject.videos.map((video) => (
                <div
                  key={video.id}
                  data-id={video.id}
                  className={`card-project ${selectedProject.colorClass}`}
                  onClick={() => {
                    if (!editingVideoId) {
                      setSelectedVideoId(video.id === selectedVideoId ? null : video.id);
                    }
                  }}
                >
                  {/* Отображение видео */}
                  {selectedVideoId === video.id && (
                    <div className="project-video-container active">
                      <video
                        controls
                        className="project-video"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <source src={video.url} type="video/mp4" />
                        Ваш браузер не поддерживает воспроизведение видео.
                      </video>
                    </div>
                  )}
                  <div className="project-info">
                    <div
                      className="project-name"
                      contentEditable={editingVideoId === video.id}
                      suppressContentEditableWarning
                      ref={(el) => (nameRefs.current[video.id] = el)}
                      onKeyDown={(e) => {
                        if (editingVideoId === video.id && e.key === 'Enter') {
                          e.preventDefault();
                          handleSave(video.id);
                        }
                      }}
                      onClick={(e) => {
                        if (editingVideoId === video.id) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      {video.name}
                    </div>
                    <div
                      className="project-description"
                      contentEditable={editingVideoId === video.id}
                      suppressContentEditableWarning
                      ref={(el) => (descriptionRefs.current[video.id] = el)}
                      onKeyDown={(e) => {
                        if (editingVideoId === video.id && e.key === 'Enter') {
                          e.preventDefault();
                          handleSave(video.id);
                        }
                      }}
                      onClick={(e) => {
                        if (editingVideoId === video.id) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      {video.description}
                    </div>
                    <p className="project-upload-date">
                      {new Date(video.upload_date).toLocaleDateString()}
                    </p>
                  </div>

                  {/* Кнопка с тремя точками */}
                  <img
                    src={ellipsisIcon}
                    alt="Опции"
                    className="ellipsis-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEllipsisClick(video.id);
                    }}
                  />

                  {/* Меню опций */}
                  {openMenuVideoId === video.id && (
                    <div className="options-menu" data-id={video.id}>
                      <button
                        className="options-menu-item"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditClick(e, video.id);
                          setOpenMenuVideoId(null);
                        }}
                      >
                        Редактировать
                      </button>
                      <button
                        className="options-menu-item"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownload(e, video.url, video.name);
                          setOpenMenuVideoId(null);
                        }}
                      >
                        Скачать
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="cards-project">
            {projects.length === 0 ? (
              <div className="empty-message">
                Проектов пока нет. Создайте новый проект, чтобы начать!
              </div>
            ) : (
              projects.map((project) => (
                <div
                  key={project.project_id}
                  className={`card-project ${project.colorClass}`}
                  onClick={() => handleProjectClick(project)}
                >
                  <div className="project-info">
                    <div className="project-name">{project.name}</div>
                    <div className="project-description">{project.description}</div>
                    <p className="project-upload-date">
                      {new Date(project.upload_date).toLocaleDateString()}
                    </p>
                    <p className="project-video-count">
                      Видео в проекте: {project.videos.length}
                    </p>
                  </div>
                  <img
                    src={trashIcon}
                    alt="Удалить проект"
                    className="delete-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      openModal(project.project_id, 'project');
                    }}
                  />
                </div>
              ))
            )}
          </div>
        )}
      </div>

      {showModal && (
        <div className={`modal-project ${showModal ? 'show' : ''}`}>
          <div className="modal-project-content">
            {deleteType === 'project' && <p>Вы уверены, что хотите удалить проект?</p>}
            <div className="modal-project-buttons">
              <button
                className="modal-project-button confirm"
                onClick={() => {
                  if (deleteType === 'project') {
                    handleProjectDelete(projectToDelete);
                  }
                }}
              >
                Да
              </button>
              <button className="modal-project-button cancel" onClick={closeModal}>
                Нет
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectListPage;
