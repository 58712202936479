import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import defaultAvatar from '../icons/avatar.png';
import './Header.css';
import UserContext from '../UserContext';
import api from '../api';
import SubscriptionModal from '../subscription_modal/SubscriptionModal';

const Header = () => {
  const { user } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);  // Добавляем состояние для модального окна
  const navigate = useNavigate();

  const handleUserClick = (event) => {
    event.preventDefault();
    navigate('/user');
  };

  const openModal = () => setIsModalOpen(true);  // Функция для открытия модального окна
  const closeModal = () => setIsModalOpen(false);  // Функция для закрытия модального окна

  const subscriptions = [
    { id: 1, name: 'Free', price: '0 rub / month', products: { Content: 60, }, note: 'Includes watermark', privileges: ['Basic support'], features: { 'Chat support': false} },
    { id: 2, name: 'Pro', price: '390 rub / month', products: { Content: 350 }, privileges: ['Basic support', 'Analytics in chat'], features: { 'Chat support': true, 'Own analytic': false } },
    { id: 3, name: 'Legend', price: '850 rub / month', products: { Content: 900 }, privileges: ['Full technical support', 'Chat support', 'Own analytic'], features: { 'Chat support': true} },
  ];

  const handleSubscriptionChange = (subscriptionTypeId) => {
    // Логика изменения подписки (может быть аналогична UserPage)
  };

  return (
    <header className="header">
      <div className="user-info">
        <button className="upgrade-btn" onClick={openModal}>Upgrade</button>
        <a href="#" className="user-link" onClick={handleUserClick}>
          <img
            src={user && user.avatarUrl ? `${api.defaults.baseURL}${user.avatarUrl}?token=${localStorage.getItem('token')}` : defaultAvatar}
            alt="User"
            className="user-icon"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultAvatar;
              console.error('Error loading avatar image');
            }}
          />
          {user ? user.firstName : 'User'}
        </a>
      </div>
      <SubscriptionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSelect={handleSubscriptionChange}
        subscriptions={subscriptions}
        currentSubscriptionId={user ? user.subscriptionId : null}
        userId={user ? user.userId : null}
      />
    </header>
  );
};

export default Header;
