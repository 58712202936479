import React, { useState } from 'react';
import './HelpPage.css';
import featuresIcon from '../icons/features.png';
import faqIcon from '../icons/faq.png';
import gettingStartedIcon from '../icons/getting_started.png';
import searchIcon from '../icons/search.png';
import gridViewIcon from '../icons/grid_view.png';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';

const HelpPage = () => {
  const [showFaqModal, setShowFaqModal] = useState(false);
  const [showGettingStartedModal, setShowGettingStartedModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [highlightedText, setHighlightedText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [activeModalContent, setActiveModalContent] = useState('');

  const categories = [
    { id: 1, icon: featuresIcon, title: 'Send us your request', articles: 1, lastUpdate: '1 day ago' },
    { id: 2, icon: faqIcon, title: 'FAQ', articles: 5, lastUpdate: '1 week ago' },
    { id: 3, icon: gettingStartedIcon, title: 'Getting Started', articles: 1, lastUpdate: '2 weeks ago' },
  ];

  const faqContent = `
    <p><strong>Вопрос:</strong> Зачем вы сделали это приложение? Для кого оно?</p>
    <p><strong>Ответ:</strong> Мы разработали это приложение, чтобы упростить создание рилсов, шортсов и так далее. Это приложение нужно для всех, кто хочет быстро и качественно создавать контент для социальных сетей.</p>
    <p><strong>Вопрос:</strong> Как загрузить видео?</p>
    <p><strong>Ответ:</strong> Вы можете загрузить видео как файл или вставить ссылку с YouTube на видео, которое хотите обработать.</p>
    <p><strong>Вопрос:</strong> Какие форматы видео поддерживаются?</p>
    <p><strong>Ответ:</strong> Мы поддерживаем все популярные форматы видео, включая MP4, AVI, MOV и другие.</p>
    <p><strong>Вопрос:</strong> Как использовать функцию AI?</p>
    <p><strong>Ответ:</strong> Выберите категорию AI, загрузите видео, и наш алгоритм автоматически создаст рилс или шортс с субтитрами и смайликами.</p>
    <p><strong>Вопрос:</strong> Как работает функция Face Tracking?</p>
    <p><strong>Ответ:</strong> Функция Face Tracking использует искусственный интеллект для отслеживания лиц на видео и автоматического кадрирования на основе движения.</p>
  `;

  const gettingStartedContent = `
    <p><strong>1.</strong> Зайдите в раздел создания проекта -> New Project</p>
    <p><strong>2.</strong> Загрузите ваше видео как файл или просто вставьте ссылку с YouTube на видео, которое вы хотите обработать.</p>
    <p><strong>3.</strong> Выберите категорию AI, Cut или Subtitles.</p>
    <p><strong>AI:</strong> Полностью нарезает, вставляет субтитры и смайлики в ваше видео, делает под формат рилса.</p>
    <p><strong>Cut:</strong> Делает умную нарезку, либо делает с функцией Face Tracking, также можно выбрать другие параметры.</p>
    <p><strong>Subtitles:</strong> Вставляет субтитры, смайлики в ваше видео, можно также настраивать параметры.</p>
    <p><strong>4.</strong> Ждете =)</p>
    <p><strong>5.</strong> Получаете готовое видео, которое потом всегда можно найти в Project List.</p>
  `;

  const handleSendRequest = () => {
    window.open('https://t.me/klipai', '_blank');
  };

  const handleFaqClick = () => {
    setActiveModalContent(faqContent);
    setShowFaqModal(true);
    setHighlightedText('');
  };

  const handleGettingStartedClick = () => {
    setActiveModalContent(gettingStartedContent);
    setShowGettingStartedModal(true);
    setHighlightedText('');
  };

  const closeModal = () => {
    setShowFaqModal(false);
    setShowGettingStartedModal(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    const results = [];
    if (faqContent.includes(value)) {
      results.push({ title: 'FAQ', content: faqContent });
    }
    if (gettingStartedContent.includes(value)) {
      results.push({ title: 'Getting Started', content: gettingStartedContent });
    }
    setSearchResults(results);
  };

  const handleSearchResultClick = (result) => {
    setActiveModalContent(result.content);
    setHighlightedText(searchText);
    if (result.title === 'FAQ') {
      setShowFaqModal(true);
    } else if (result.title === 'Getting Started') {
      setShowGettingStartedModal(true);
    }
    setSearchResults([]);
  };

    const getHighlightedText = (text, highlight) => {
        if (!highlight) {
            return text;
        }
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? `<mark>${part}</mark>` : part
        ).join('');
    };


  return (
    <div className="video-editor">
      <Sidebar />
      <div className="main-container-help">
        <Header />
        <div className="help-container">
          <h1>Your Help Center</h1>
          <p>How can we help you?</p>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search for answers"
              value={searchText}
              onChange={handleSearch}
            />
            <button><img src={searchIcon} alt="Search" /></button>
          </div>
          {searchResults.length > 0 && (
            <div className="search-results">
              {searchResults.map((result, index) => (
                <div
                  key={index}
                  className="search-result"
                  onClick={() => handleSearchResultClick(result)}
                >
                  {result.title}
                </div>
              ))}
            </div>
          )}
          <div className="view-toggle">
            <button className="grid-view"><img src={gridViewIcon} alt="Grid View" /></button>
          </div>
          <div className="categories-container">
            {categories.map(category => (
              <div
                key={category.id}
                className="category-block"
                onClick={
                  category.title === 'Send us your request' ? handleSendRequest :
                  category.title === 'FAQ' ? handleFaqClick :
                  category.title === 'Getting Started' ? handleGettingStartedClick :
                  null
                }
              >
                <img src={category.icon} alt={category.title} className="category-icon" />
                <h3>{category.title}</h3>
                <p>{category.articles} articles</p>
                <p>Last Update {category.lastUpdate}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showFaqModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <h2>FAQ</h2>
            <div dangerouslySetInnerHTML={{ __html: getHighlightedText(activeModalContent, highlightedText) }} />
          </div>
        </div>
      )}
      {showGettingStartedModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <h2>Getting Started</h2>
            <div dangerouslySetInnerHTML={{ __html: getHighlightedText(activeModalContent, highlightedText) }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpPage;
