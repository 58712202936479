// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Commercial.css */
.commercial-container {
  font-family: 'Arial', sans-serif;
  color: #333;
  background: #f4f4f8;
  padding: 20px;
}

.title-commercial {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.content-commercial {
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.section-title-commercial {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #007BFF;
}

.paragraph-commercial {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/commercial/Commercial.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,gCAAgC;EAChC,WAAW;EACX,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["/* src/pages/Commercial.css */\n.commercial-container {\n  font-family: 'Arial', sans-serif;\n  color: #333;\n  background: #f4f4f8;\n  padding: 20px;\n}\n\n.title-commercial {\n  font-size: 36px;\n  font-weight: bold;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.content-commercial {\n  padding: 10px;\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n}\n\n.section-title-commercial {\n  font-size: 24px;\n  font-weight: bold;\n  margin-top: 20px;\n  margin-bottom: 10px;\n  color: #007BFF;\n}\n\n.paragraph-commercial {\n  font-size: 16px;\n  line-height: 1.6;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
