import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../VideoEditor.css';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import PreferencesContainer from '../preferences/PreferencesContainer';
import '../preferences/PreferencesContainer.css';
import api from '../api';

const AiPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { processingId } = location.state || {};
  const [error, setError] = useState(null);
  const [subSettings, setSubSettings] = useState({
    subtitles_flag: true,
    subtitles_preset_flag: true,
    subtitles_preset_type: 6,
    font: "Arial",
    font_size: "M",
    main_color: "black",
    border_flag: true,
    border_color: "black",
    border_thickness: "M",
    highlight_flag: true,
    highlight_color: "green",
    position: 60,
    emoji_flag: false,
    emoji_size_coef: 0.125,
    emoji_position: 55,
    aspect_ratio: "9:16",
    brainrot_flag: false,
    brainrot_footage: 1,
    face_tracking_flag: true,
    video_max_length: 15,
    subtitles_language: 'rus'
  });

  const convertFontSizeToSubtitleSize = (fontSize) => {
    const minFontSize = 15;
    const maxFontSize = 50;
    const minSubtitleSize = 0.025;
    const maxSubtitleSize = 0.10;
    return ((fontSize - minFontSize) / (maxFontSize - minFontSize)) * (maxSubtitleSize - minSubtitleSize) + minSubtitleSize;
  };

  const convertPositionToSubtitlePosition = (position) => {
    const minPosition = 10;
    const maxPosition = 80;
    const minSubtitlePosition = 0.15;
    const maxSubtitlePosition = 0.65;
    return ((position - minPosition) / (maxPosition - minPosition)) * (maxSubtitlePosition - minSubtitlePosition) + minSubtitlePosition;
  };

  const handleCreateVideo = async () => {
    setError(null);

    const adjustedSubSettings = {
      ...subSettings,
      size: convertFontSizeToSubtitleSize(subSettings.size),
      position: convertPositionToSubtitlePosition(subSettings.position)
    };

    // Check if processingId is available
    if (!processingId) {
      setError('Processing ID is missing.');
      return;
    }

    // Redirect to the processing page with the initial state
    navigate('/processing', { state: { processingId } });

    try {
      const response = await api.post('/process_full_pipeline', {
        process_id: processingId,
        sub_settings: adjustedSubSettings
      });

      // Предполагаем, что ответ API содержит путь к итоговому видео
      const outputVideo = response.data.output_video;

      // Переход на страницу проекта с URL видео
      navigate('/project-ready', { state: { outputVideo, processingId } });
    } catch (error) {
      let errorMessage;

      // Проверяем, есть ли ответ от сервера
      if (error.response) {
        console.error('Ошибка ответа от сервера:', error.response.data);
        errorMessage = error.response.data.msg || 'Ошибка на сервере. Попробуйте снова.';
      } else if (error.request) {
        console.error('Ошибка запроса:', error.request);
        errorMessage = 'Не удалось получить ответ от сервера. Проверьте соединение и попробуйте снова.';
      } else {
        console.error('Ошибка при настройке запроса:', error.message);
        errorMessage = 'Произошла ошибка при отправке запроса. Попробуйте снова.';
      }

      // Переход на страницу ProcessingPage с передачей ошибки и других данных
      navigate('/processing', { state: { processingId, error: errorMessage } });
    }
  };

  return (
    <div className="video-editor">
      <Sidebar />
      <div className="main-container-ai">
        <Header />
        <PreferencesContainer
          handleCreateVideo={handleCreateVideo}
          subSettings={subSettings}
          setSubSettings={setSubSettings}
          showFontOptions={true}
          showFontSizeOptions={true}
          showTextColorOptions={true}
          showBackgroundColorOptions={true}
          showTextPositionOptions={true}
          showEmojiOptions={true}
          showFaceTracking={true}
          showPresetSubtitlesFlag={true}
          showPresetSubtitlesTypes={true}
          showAspectRatioOptions={true}
          showDuration={true}
        />
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default AiPage;
