import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api';  // Импортируем api
import './password_change.css';  // Импортируем стили

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    if (!token) {
      setErrorMessage('Invalid or missing token.');
    }
    setToken(token);
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const response = await api.post(`/reset_password/${token}`, {  // Используем api.post
        new_password: newPassword,
        confirm_password: confirmPassword,
      });

      if (response.status === 200) {
        alert('Password updated successfully!');
        navigate('/login'); // Перенаправление на страницу входа после успешного сброса пароля
      } else {
        setErrorMessage(response.data.error || 'Failed to reset password.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setErrorMessage('Failed to reset password. Please try again later.');
    }
  };

  return (
    <div className="reset-password-body">
      <div className="reset-password-container">
        <h2>Reset Your Password</h2>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button type="submit">Change Password</button>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
