import './Modal.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Modal = ({ show, onClose, pendingLoginDetails, login }) => {
  const navigate = useNavigate();

  const handleEntrer = async (e) => {
    e.preventDefault();
    try {
      // Логиним пользователя, используя сохранённые email и пароль
      await login(pendingLoginDetails.email, pendingLoginDetails.password);
      navigate('/'); // Перенаправляем на главную страницу после логина
    } catch (error) {
      console.error('Login error:', error);
    }
    onClose(); // Закрываем модальное окно
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Завершение регистрации</h2>
        <p>Мы отправили письмо с подтверждением на вашу электронную почту.</p>
        <button onClick={handleEntrer} className="modal-close-button">Хорошо</button>
      </div>
    </div>
  );
};

export default Modal;