import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ProjectReadyPage.css';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import projectImage from '../animated_logo.gif';
import downloadIcon from '../icons/export.png';
import UserContext from '../UserContext';
import api from '../api';

const ProjectReadyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { processingId } = location.state || {}; // Получаем processingId
  const { user, loading } = useContext(UserContext);
  const [videos, setVideos] = useState([]); // Используем массив для хранения видео

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    } else if (user && processingId) {
      const fetchFinalVideos = async () => {
        try {
          const token = localStorage.getItem('token');
          if (!token || token === 'null') {
            navigate('/login');
            return;
          }

          const response = await api.get(`/final_videos/${processingId}`);
          if (response.data) {
            const videosWithToken = response.data.map(video => ({
              ...video,
              src: `${video.src}?token=${token}`
            }));
            setVideos(videosWithToken);
          } else {
            console.log('No videos found for this processingId');
          }
        } catch (error) {
          console.error('Error fetching final videos:', error);
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
        }
      };
      fetchFinalVideos();
    }
  }, [user, loading, navigate, processingId]);

  const handleDownloadClick = (videoSrc) => {
    const link = document.createElement('a');
    link.href = videoSrc;
    link.download = videoSrc.split('/').pop(); // Имя файла берется из пути
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="video-editor">
      <Sidebar />
      <div className="main-container-project-ready">
        <Header />
        <div className="project-details">
          <div className="project-header-box">
            <div className="project-header">
              <img src={projectImage} alt="Project" />
              <div className="project-info">
                <h2>Your final project</h2>
                <p>{videos.length} Videos</p> {/* Отображаем количество видео */}
              </div>
            </div>
            <div className="main-video-container">
              <h3>Final Videos</h3>
              {videos.length > 0 ? (
                videos.map((video, index) => (
                  <div key={index} className="video-wrapper">
                    <video src={video.src} controls className="main-video">
                      <track kind="captions" />
                    </video>
                    <div className="project-actions">
                      <button className="edit-video-btn" onClick={() => handleDownloadClick(video.src)}>
                        <img className="edit-video-btn-img" src={downloadIcon} alt="Download" /> Download
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading videos...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectReadyPage;
