import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './MediaListPage.css';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import ellipsisIcon from '../icons/ellipsis.png'; // Иконка с тремя точками
import api from '../api';
import UserContext from '../UserContext';
import Loader from '../loader/Loader';

api.defaults.withCredentials = true;

const MediaListPage = () => {
  const [videos, setVideos] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [editingVideoId, setEditingVideoId] = useState(null);
  const [originalTitle, setOriginalTitle] = useState('');
  const [originalDescription, setOriginalDescription] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState(null);
  const [openMenuVideoId, setOpenMenuVideoId] = useState(null); // Новое состояние для открытого меню
  const { user, loading } = useContext(UserContext);
  const [videosLoading, setVideosLoading] = useState(true);
  const navigate = useNavigate();

  const cardColors = [
    'card-1-media',
    'card-2-media',
    'card-3-media',
    'card-4-media',
    'card-5-media',
  ];

  // Создаем объекты ref для хранения ссылок на элементы
  const titleRefs = useRef({});
  const descriptionRefs = useRef({});

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    } else if (user) {
      const fetchUserData = async () => {
        try {
          const mediaResponse = await api.get('/user_media');
          const token = localStorage.getItem('token');
          const videosWithToken = mediaResponse.data.map((video) => ({
            ...video,
            src: `${video.src}?token=${token}`,
          }));

          // Присваиваем цвета в цикле
          const videosWithColors = videosWithToken.map((video, index) => ({
            ...video,
            colorClass: cardColors[index % cardColors.length],
          }));

          setVideos(videosWithColors);
        } catch (error) {
          console.error('Error fetching media:', error);
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
        } finally {
          setVideosLoading(false);
        }
      };
      fetchUserData();
    }
  }, [user, loading, navigate]);

  const handleDelete = async (id) => {
    try {
      await api.delete(`/delete_video/${id}`);
      setVideos(videos.filter((video) => video.id !== id));
    } catch (error) {
      console.error('Error deleting video:', error);
    }
    setShowModal(false);
  };

  const handleVideoClick = (id) => {
    setSelectedVideoId(id === selectedVideoId ? null : id);
  };

  const handleEditClick = (e, videoId) => {
    e.stopPropagation();
    if (editingVideoId === videoId) {
      // Сохраняем изменения
      handleSave(videoId);
    } else {
      // Начинаем редактирование
      setEditingVideoId(videoId);
      const video = videos.find((video) => video.id === videoId);
      setOriginalTitle(video.title || video.name || '');
      setOriginalDescription(video.description || '');

      // Устанавливаем фокус на заголовок и перемещаем курсор в конец
      setTimeout(() => {
        if (titleRefs.current[videoId]) {
          const el = titleRefs.current[videoId];
          el.focus();

          // Перемещаем курсор в конец
          const range = document.createRange();
          range.selectNodeContents(el);
          range.collapse(false);
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }, 0);
    }
    setOpenMenuVideoId(null); // Закрываем меню
  };

  const handleSave = async (videoId) => {
    const titleContent = titleRefs.current[videoId]?.textContent || '';
    const descriptionContent = descriptionRefs.current[videoId]?.textContent || '';

    try {
      await api.put(`/update_video/${videoId}`, {
        title: titleContent,
        description: descriptionContent,
      });
      // Обновляем состояние
      setVideos(
        videos.map((video) =>
          video.id === videoId
            ? { ...video, title: titleContent, description: descriptionContent }
            : video
        )
      );
    } catch (error) {
      console.error('Error saving video:', error);
    } finally {
      setEditingVideoId(null);
    }
  };

  const handleCancelEdit = () => {
    if (titleRefs.current[editingVideoId]) {
      titleRefs.current[editingVideoId].textContent = originalTitle;
    }
    if (descriptionRefs.current[editingVideoId]) {
      descriptionRefs.current[editingVideoId].textContent = originalDescription;
    }
    setEditingVideoId(null);
  };

  // Добавляем эффект для закрытия видео и отмены редактирования при клике вне карточки
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectedVideoId !== null) {
        const cardElement = document.querySelector(
          `.card-media[data-id="${selectedVideoId}"]`
        );
        if (cardElement && !cardElement.contains(event.target)) {
          setSelectedVideoId(null);
        }
      }
      if (editingVideoId !== null) {
        const editingElement = document.querySelector(
          `.card-media[data-id="${editingVideoId}"]`
        );
        if (editingElement && !editingElement.contains(event.target)) {
          handleCancelEdit();
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectedVideoId, editingVideoId]);

  const openModal = (id) => {
    setVideoToDelete(id);
    setShowModal(true);
    setOpenMenuVideoId(null); // Закрываем меню
  };

  const closeModal = () => {
    setShowModal(false);
    setVideoToDelete(null);
  };

  // Функция для обработки клика по кнопке с тремя точками
  const handleEllipsisClick = (e, videoId) => {
    e.stopPropagation();
    setOpenMenuVideoId(videoId === openMenuVideoId ? null : videoId);
  };

  // Эффект для закрытия меню при клике вне его области
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (openMenuVideoId !== null) {
        const menuElement = document.querySelector(
          `.options-menu[data-id="${openMenuVideoId}"]`
        );
        if (menuElement && !menuElement.contains(event.target)) {
          setOpenMenuVideoId(null);
        }
      }
    };

    document.addEventListener('click', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, [openMenuVideoId]);

  return (
    <div className="video-editor">
      <Sidebar />
      <div className="main-container-media">
        <Header />
        {loading || videosLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : videos.length === 0 ? (
          <div className="empty-message">Тут пока пусто.</div>
        ) : (
          <div className="cards-media">
            {videos.map((video) => (
              <div
                key={video.id}
                data-id={video.id}
                className={`card-media ${video.colorClass}`}
                onClick={() => handleVideoClick(video.id)}
              >
                {/* Отображение видео */}
                {selectedVideoId === video.id && (
                  <div className="media-video-container active">
                    <video
                      controls
                      className="media-video"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <source src={video.src} type="video/mp4" />
                      Ваш браузер не поддерживает тег видео.
                    </video>
                  </div>
                )}
                <div className="media-info" id={`video-${video.id}`}>
                  <div
                    className="media-title"
                    contentEditable={editingVideoId === video.id}
                    suppressContentEditableWarning
                    ref={(el) => (titleRefs.current[video.id] = el)}
                    onKeyDown={(e) => {
                      if (editingVideoId === video.id && e.key === 'Enter') {
                        e.preventDefault();
                        handleSave(video.id);
                      }
                    }}
                    onClick={(e) => {
                      if (editingVideoId === video.id) {
                        e.stopPropagation();
                      }
                    }}
                  >
                    {video.title || video.name || ''}
                  </div>
                  <div
                    className="media-description"
                    contentEditable={editingVideoId === video.id}
                    suppressContentEditableWarning
                    ref={(el) => (descriptionRefs.current[video.id] = el)}
                    onKeyDown={(e) => {
                      if (editingVideoId === video.id && e.key === 'Enter') {
                        e.preventDefault();
                        handleSave(video.id);
                      }
                    }}
                    onClick={(e) => {
                      if (editingVideoId === video.id) {
                        e.stopPropagation();
                      }
                    }}
                  >
                    {video.description || ''}
                  </div>
                  <p className="media-upload-date">
                    {new Date(video.upload_date).toLocaleDateString()}
                  </p>
                </div>
                {/* Иконка с тремя точками */}
                <img
                  src={ellipsisIcon}
                  alt="Опции"
                  className="ellipsis-icon-media"
                  onClick={(e) => handleEllipsisClick(e, video.id)}
                />
                {/* Меню опций */}
                {openMenuVideoId === video.id && (
                  <div className="options-menu" data-id={video.id}>
                    <button
                      className="options-menu-item"
                      onClick={(e) => handleEditClick(e, video.id)}
                    >
                      Редактировать
                    </button>
                    <button
                      className="options-menu-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        openModal(video.id);
                      }}
                    >
                      Удалить
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {showModal && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <p>Вы уверены, что хотите удалить видео?</p>
            <div className="modal-buttons">
              <button
                className="modal-button confirm"
                onClick={() => handleDelete(videoToDelete)}
              >
                Да
              </button>
              <button className="modal-button cancel" onClick={closeModal}>
                Нет
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaListPage;
