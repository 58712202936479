import React, { useState } from 'react';
import emojiIcon from '../emoji.png';
import preset1 from '../fonts_style/1.png';
import preset2 from '../fonts_style/2.png';
import preset3 from '../fonts_style/3.png';
import preset4 from '../fonts_style/4.png';
import preset5 from '../fonts_style/5.png';
import preset6 from '../fonts_style/6.png';
import './PreferencesContainer.css';
import api from '../api';

const PreferencesContainer = ({
  handleCreateVideo,
  subSettings,
  setSubSettings,
  showPresetSubtitlesTypes = true,
  showCustomStyle = true,
  showFontOptions = true,
  showFontSizeOptions = true,
  showTextColorOptions = true,
  showWordHighlight = true,
  showTextPositionOptions = true,
  showEmojiOptions = true,
  showAspectRatioOptions = true,
  showFaceTracking = true,
  showBorderFlag = true,
  showTextBorderColorOptions = true,
  showBorderSizeOptions = true,
  showPresetSubtitlesFlag = true,
  showWordHighlightColor = true,
  showBrainrot = true,
  showBrainrotFootage = true,
  showSubtitlesFlag = true,
  showDuration = true
}) => {
  const [activeTab, setActiveTab] = useState(
    showDuration ? 'Duration' :
    showSubtitlesFlag ? 'Subtitles' :
    showEmojiOptions ? 'Emoji' :
    showBrainrot ? 'Brainrot' :
    showFaceTracking ? 'FaceTracking' :
    showAspectRatioOptions ? 'AspectRatio' : ''
  );
  const [showCustom, setShowCustom] = useState(false);
  const [showPreset, setShowPreset] = useState(true);
  const [errorMessage, setErrorMessage] = useState(''); // Новое состояние для сообщения об ошибке

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSubSettings((prevSettings) => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handlePresetClick = (preset) => {
    setSubSettings({ ...subSettings, subtitles_preset_type: preset.value, subtitles_preset_flag: true });
  };

  const handleCustomClick = () => {
    setShowCustom(!showCustom);
    setShowPreset(false);
    setSubSettings({ ...subSettings, subtitles_preset_flag: false });
  };

  const presets = [
    { value: 1, label: 'Preset 1', image: preset1 },
    { value: 2, label: 'Preset 2', image: preset2 },
    { value: 3, label: 'Preset 3', image: preset3 },
    { value: 4, label: 'Preset 4', image: preset4 },
    { value: 5, label: 'Preset 5', image: preset5 },
    { value: 6, label: 'Preset 6', image: preset6 }
  ];

  const getFontSize = (size) => {
    switch (size) {
      case 'S':
        return '12px';
      case 'M':
        return '16px';
      case 'L':
        return '20px';
      default:
        return '16px';
    }
  };

    const checkSubscriptionLimit = async () => {
      try {
        const response = await api.post('/check_subscription_limit');
        return response.status === 200;
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setErrorMessage(error.response.data.error); // Устанавливаем сообщение об ошибке
        } else {
          console.error('Error checking subscription limit:', error);
        }
        return false;
      }
    };

  const handleCreateVideoClick = async () => {
    const isAllowed = await checkSubscriptionLimit();
    if (isAllowed) {
      handleCreateVideo();
    }
  };

  return (
    <div className="content-container">
      <div className="preferences-container-ai">
        <div className="preference-box">
          <h1>Settings</h1>
          <p>Choose anything you want before we start - styles, subtitle amount, video length etc.</p>
          <div className="tab-nav">
            {showDuration && (
              <button className={activeTab === 'Duration' ? 'tab-active' : ''} onClick={() => setActiveTab('Duration')}>Duration</button>
            )}
            {showSubtitlesFlag && (
              <button className={activeTab === 'Subtitles' ? 'tab-active' : ''} onClick={() => setActiveTab('Subtitles')}>Subtitles</button>
            )}
            {showEmojiOptions && (
              <button className={activeTab === 'Emoji' ? 'tab-active' : ''} onClick={() => setActiveTab('Emoji')}>Emoji</button>
            )}
            {showAspectRatioOptions && (
              <button className={activeTab === 'AspectRatio' ? 'tab-active' : ''} onClick={() => setActiveTab('AspectRatio')}>Aspect Ratio</button>
            )}
            {showFaceTracking && (
              <button className={activeTab === 'FaceTracking' ? 'tab-active' : ''} onClick={() => setActiveTab('FaceTracking')}>Face Tracking</button>
            )}
            {showBrainrot && (
              <button className={activeTab === 'Brainrot' ? 'tab-active' : ''} onClick={() => setActiveTab('Brainrot')}>Brainrot</button>
            )}
          </div>
          <div className={`tab-content tab-${activeTab.toLowerCase()}`}>
            {activeTab === 'Emoji' && showEmojiOptions && (
              <>
                <div className="options-row">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2>Emoji</h2>
                    <input
                      type="checkbox"
                      name="emoji_flag"
                      checked={subSettings.emoji_flag}
                      onChange={() => handleInputChange({ target: { name: 'emoji_flag', type: 'checkbox', checked: !subSettings.emoji_flag } })}
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                </div>
                {subSettings.emoji_flag && (
                  <div className="options-row">
                    <h2>Emoji Position</h2>
                    <input
                      type="range"
                      name="emoji_position"
                      min="0"
                      max="85"
                      value={subSettings.emoji_position}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
              </>
            )}
            {activeTab === 'Duration' && showDuration && (
                          <div className="options-row">
                            <h2>Duration</h2>
                            <select name="video_max_length" value={subSettings.video_max_length} onChange={handleInputChange}>
                              <option value="15">15s</option>
                              <option value="30">30s</option>
                              <option value="60">60s</option>
                              <option value="120">2m</option>
                              <option value="180">3m</option>
                            </select>
                          </div>
                        )}
            {activeTab === 'Subtitles' && showSubtitlesFlag && (
              <>
                <div className="options-row">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2>Subtitles</h2>
                    <input
                      type="checkbox"
                      name="subtitles_flag"
                      checked={subSettings.subtitles_flag}
                      onChange={() => handleInputChange({ target: { name: 'subtitles_flag', type: 'checkbox', checked: !subSettings.subtitles_flag} })}
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                </div>
                {showSubtitlesFlag && (
                    <div className="options-row">
                    <h2>Video Language</h2>
                    <select name="subtitles_language" value={subSettings.subtitles_language} onChange={handleInputChange}>
                      <option value="rus">Russian</option>
                      <option value="eng">English</option>
                    </select>
                  </div>
                )}
                {showPresetSubtitlesFlag && (
                  <>
                    <div className="type-options">
                      {presets.map(preset => (
                        <img
                          key={preset.value}
                          src={preset.image}
                          alt={preset.label}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handlePresetClick(preset)}
                        />
                      ))}
                    </div>
                    <button className="styled-button" onClick={handleCustomClick}>Custom Style</button>
                    {showCustom && (
                      <div className="custom-container">
                        {showFontOptions && (
                          <div className="options-row">
                            <h2>Font</h2>
                            <select name="font" value={subSettings.font} onChange={handleInputChange}>
                              <option value="Arial">Arial</option>
                              <option value="Impact">Impact</option>
                              <option value="Comic Sans MS">Comic Sans MS</option>
                              <option value="Courier">Courier</option>
                              <option value="Yeast">Yeast</option>
                              <option value="Nunito">Nunito</option>
                              <option value="Copperplate">Copperplate</option>
                            </select>
                          </div>
                        )}
                        {showFontSizeOptions && (
                          <div className="options-row">
                            <h2>Font Size</h2>
                            <select name="font_size" value={subSettings.font_size} onChange={handleInputChange}>
                              <option value="S">S</option>
                              <option value="M">M</option>
                              <option value="L">L</option>
                            </select>
                          </div>
                        )}
                        {showTextColorOptions && (
                          <div className="options-row">
                            <h2>Text Color</h2>
                            <input type="color" name="main_color" value={subSettings.main_color} onChange={handleInputChange} />
                          </div>
                        )}
                        {showBorderFlag &&
                          <div className="options-row">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <h2>Border Line</h2>
                              <input
                                type="checkbox"
                                name="border_flag"
                                checked={subSettings.border_flag}
                                onChange={() => handleInputChange({ target: { name: 'border_flag', type: 'checkbox', checked: !subSettings.border_flag } })}
                                style={{ marginLeft: '10px' }}
                              />
                            </div>
                          </div>
                        }
                        {subSettings.border_flag && showTextBorderColorOptions && (
                          <div className="options-row">
                            <h2>Border Color</h2>
                            <input type="color" name="border_color" value={subSettings.border_color} onChange={handleInputChange} />
                          </div>
                        )}
                        {subSettings.border_flag && showBorderSizeOptions && (
                          <div className="options-row">
                            <h2>Border Thickness</h2>
                            <select name="border_thickness" value={subSettings.border_thickness} onChange={handleInputChange}>
                              <option value="S">S</option>
                              <option value="M">M</option>
                              <option value="L">L</option>
                            </select>
                          </div>
                        )}
                        {showWordHighlightColor && (
                          <div className="options-row">
                            <h2>Highlight Color</h2>
                            <input type="color" name="highlight_color" value={subSettings.highlight_color} onChange={handleInputChange} />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                {showTextPositionOptions && (
                  <div className="options-row">
                    <h2>Text Position</h2>
                    <input
                      type="range"
                      name="position"
                      min="10"
                      max="80"
                      value={subSettings.position}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
              </>
            )}
            {activeTab === 'Brainrot' && showBrainrot && (
              <>
                {showBrainrot &&
                  <div className="options-row">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h2>Brainrot</h2>
                      <input
                        type="checkbox"
                        name="brainrot_flag"
                        checked={subSettings.brainrot_flag}
                        onChange={() => handleInputChange({ target: { name: 'brainrot_flag', type: 'checkbox', checked: !subSettings.brainrot_flag } })}
                        style={{ marginLeft: '10px' }}
                      />
                    </div>
                  </div>
                }
                {showBrainrotFootage && (
                  <div className="options-row">
                    <h2>Footage</h2>
                    <select name="brainrot_footage" value={subSettings.brainrot_footage} onChange={handleInputChange}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>
                )}
              </>
            )}
            {activeTab === 'FaceTracking' && showFaceTracking && (
              <div className="options-row">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h2>Face Tracking</h2>
                  <input
                    type="checkbox"
                    name="face_tracking_flag"
                    checked={subSettings.face_tracking_flag}
                    onChange={() => handleInputChange({ target: { name: 'face_tracking_flag', type: 'checkbox', checked: !subSettings.face_tracking_flag } })}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              </div>
            )}
            {activeTab === 'AspectRatio' && showAspectRatioOptions && (
              <div className="options-row">
                <h2>Aspect Ratio</h2>
                <select name="aspect_ratio" value={subSettings.aspect_ratio} onChange={handleInputChange}>
                  <option value="Original">Original</option>
                  <option value="9:16">9:16</option>
                  <option value="1:1">1:1</option>
                </select>
              </div>
            )}
          </div>
        </div>
        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
        <button className="get-ai-clips" onClick={handleCreateVideoClick}>Create Video</button>
      </div>
      <div className="preview-container">
        <div className="phone-mockup">
          <div
            className="preview-aspect-ratio"
            style={{
              paddingBottom: subSettings.aspect_ratio === '9:16' ? '180%' : '100%',
            }}
          >
            {subSettings.subtitles_preset_flag ? (
              <img
                src={presets.find(preset => preset.value === subSettings.subtitles_preset_type)?.image}
                alt="Selected Preset"
                style={{
                  width: '80%',
                  position: 'absolute',
                  top: `${subSettings.position}%`,
                  transform: 'translateY(80%)',
                }}
              />
            ) : (
              <div
                className="preview-text"
                style={{
                  fontFamily: subSettings.font,
                  fontSize: getFontSize(subSettings.size),
                  color: subSettings.main_color,
                  top: `${subSettings.position}%`,
                  transform: 'translateY(-50%)',
                  fontWeight: subSettings.bold ? 'bold' : 'normal',
                  border: subSettings.border_flag ? `${subSettings.border_thickness} solid ${subSettings.border_color}` : 'none',
                }}
              >
                Sample Text
              </div>
            )}
            {subSettings.emoji_flag && (
              <img
                src={emojiIcon}
                alt="Emoji"
                className="preview-emoji"
                style={{ top: `${subSettings.emoji_position}%` }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferencesContainer;
