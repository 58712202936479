import axios from 'axios';

const api = axios.create({
  baseURL: window.location.hostname === 'localhost'
            ? 'http://localhost:8000'
            : 'https://klipai.ru/backend',
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Список маршрутов аутентификации
    const authUrls = ['/login', '/register', '/refresh'];

    // Проверяем, является ли запрос к маршруту аутентификации
    const isAuthUrl = authUrls.some(url => originalRequest.url.includes(url));

    if (error.response.status === 401 && !originalRequest._retry && !isAuthUrl) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          window.location.href = '/login';
          return Promise.reject(error);
        }
        const { data } = await axios.post('/refresh', null, {
          baseURL: api.defaults.baseURL,
          headers: { 'Authorization': `Bearer ${refreshToken}` }
        });
        localStorage.setItem('token', data.access_token);
        api.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
        originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
        return api(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
