import React, { useState, useContext } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import api from '../api';
import Modal from '../components/Modal';
import zxcvbn from 'zxcvbn'; // Для оценки сложности пароля

const LoginPage = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState(''); // Обязательное поле
  const [phone, setPhone] = useState(''); // Номер телефона
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [showModal, setShowModal] = useState(false); // Модальное окно
  const [passwordStrength, setPasswordStrength] = useState(0); // Сложность пароля
  const [showPassword, setShowPassword] = useState(false); // Отображение пароля
  const [isForgotPassword, setIsForgotPassword] = useState(false); // Сброс пароля
  const [resetEmail, setResetEmail] = useState(''); // Email для сброса пароля
  const [pendingLoginDetails, setPendingLoginDetails] = useState({ email: '', password: '' });

  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  const handleToggle = () => {
    setIsSignUp(!isSignUp);
    resetMessages();
  };

  const resetMessages = () => {
    setErrorMessage('');
    setErrors({});
    setSuccessMessage('');
  };

  const validateName = (name) => {
    const namePattern = /^[A-Za-zА-Яа-яЁё0-9\s'/-]{2,50}$/;
    if (!namePattern.test(name)) {
      return 'Invalid name format';
    }
    return '';
  };

  const validatePhone = (phone) => {
    const phonePattern = /^7\d{10}$/; // Формат телефона должен начинаться с "7" и содержать 11 цифр
    if (!phonePattern.test(phone)) {
      return 'Invalid phone number format.';
    }
    return '';
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setErrorMessage('Please enter both email and password.');
      return;
    }

    try {
      await login(email, password);
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage(error.response?.data?.error || 'Invalid email or password.');
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!firstName) {
      newErrors.firstName = true;
    }

    if (!lastName) {
      newErrors.lastName = true;
    }

    const phoneError = validatePhone(phone);
    if (phoneError) {
      newErrors.phone = phoneError;
    }

    if (!email) {
      newErrors.email = true;
    } else {
      const emailError = await validateEmail(email);
      if (emailError) {
        newErrors.email = emailError;
      }
    }

    if (!password) {
      newErrors.password = true;
    } else if (passwordStrength < 3) {
      newErrors.password = 'Password is too weak';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await api.post('/register', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        phone: phone
      });
      resetMessages();

      // Сохраняем email и пароль для последующего логина
      setPendingLoginDetails({ email, password });

      setShowModal(true); // Показываем модальное окно
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'Registration failed. Please try again.');
    }
  };

  // Функция для проверки email
  const validateEmail = async (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      return 'Invalid email format';
    }

    try {
      const response = await api.post('/check_email', { email });
      if (response.data.exists) {
        return 'Email already exists';
      }
    } catch (error) {
      return 'Error checking email';
    }

    return '';
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;

    // Скрываем ошибку при изменении поля
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });

    if (field === 'firstName') setFirstName(value);
    if (field === 'lastName') setLastName(value);
    if (field === 'email') setEmail(value);
    if (field === 'password') {
      setPassword(value);
      handlePasswordChange(value);
    }
    if (field === 'phone') {
      if (/^\d*$/.test(value)) { // Разрешаем только цифры
        setPhone(value);
      }
    }
  };

  const handlePasswordChange = (password) => {
    const result = zxcvbn(password);
    setPasswordStrength(result.score);
  };

  const handleCloseModal = async () => {
    setShowModal(false);

    try {
      // Логиним пользователя после закрытия модального окна
      await login(pendingLoginDetails.email, pendingLoginDetails.password);
      navigate('/'); // Перенаправляем на главную страницу
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    resetMessages();
  };

  const handleSendResetLink = async (e) => {
    e.preventDefault();

    try {
      await api.post('/send_reset_email', { email: resetEmail });
      setSuccessMessage('Reset link sent. Please check your email.');
      setErrorMessage('');
    } catch (error) {
      console.error('Reset email error:', error);
      setErrorMessage('Failed to send reset email. Please try again.');
    }
  };

  const handleGoBack = () => {
    setIsForgotPassword(false);
    setErrorMessage('');
  };

  return (
    <div className="login-page-body">
      <div className={`container-login ${isSignUp ? 'right-panel-active-login' : ''}`} id="container">
        <div className="form-container-login sign-up-container-login">
          <form className="login-page-form" onSubmit={handleSignUp}>
            <h1 className="login-page-h1">Create Account</h1>

            <input
              type="text"
              className={`login-page-input ${errors.firstName ? 'input-error' : ''}`}
              placeholder="First Name"
              value={firstName}
              onChange={(e) => handleInputChange(e, 'firstName')}
            />
            {errors.firstName && <p className="field-error">{errors.firstName}</p>}

            <input
              type="text"
              className={`login-page-input ${errors.lastName ? 'input-error' : ''}`}
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => handleInputChange(e, 'lastName')}
            />
            {errors.lastName && <p className="field-error">{errors.lastName}</p>}

            <input
              type="text"
              className={`login-page-input ${errors.phone ? 'input-error' : ''}`}
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => handleInputChange(e, 'phone')}
            />
            {errors.phone && <p className="field-error">{errors.phone}</p>}

            <input
              type="email"
              className={`login-page-input ${errors.email ? 'input-error' : ''}`}
              placeholder="Email"
              value={email}
              onChange={(e) => handleInputChange(e, 'email')}
            />
            {errors.email && typeof errors.email === 'string' && <p className="field-error">{errors.email}</p>}

            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                className={`login-page-input ${errors.password ? 'input-error' : ''}`}
                placeholder="Password"
                value={password}
                onChange={(e) => handleInputChange(e, 'password')}
              />
              <button type="button" onClick={togglePasswordVisibility}>
                <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
              </button>
            </div>
            {/* Ошибка пароля, если она есть в errors */}
            {errors.password && typeof errors.password === 'string' && <p className="field-error">{errors.password}</p>}

            {/* Индикатор сложности пароля */}
            {!errors.password && password && (
              <div className={`password-strength strength-${passwordStrength}`}>
                {['Very weak', 'Weak', 'Okay', 'Good', 'Strong'][passwordStrength]}
              </div>
            )}

            <button type="submit" className="login-page-button">Sign Up</button>
          </form>
        </div>

        <div className="form-container-login sign-in-container-login">
          {isForgotPassword ? (
            <form className="login-page-form" onSubmit={handleSendResetLink}>
              <h1 className="login-page-h1">Recover Account</h1>
              <p className="login-page-p">Enter the email address associated with the account you wish to recover.</p>
              <input
                type="email"
                className="login-page-input"
                placeholder="Enter your email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />

              {errorMessage && <p className="field-error">{errorMessage}</p>}
              {successMessage && <p className="success-message">{successMessage}</p>}

              <div className="button-container">
                <button type="submit" className="recover-button">Recover</button>
                <button type="button" className="go-back-button" onClick={handleGoBack}>Go Back</button>
              </div>
            </form>
          ) : (
            <form className="login-page-form" onSubmit={handleLogin}>
              <h1 className="login-page-h1">Sign in</h1>
              <input
                type="email"
                className={`login-page-input ${errors.email ? 'input-error' : ''}`}
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrorMessage('');
                }}
              />

              {/* Блок с глазом для пароля */}
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  className={`login-page-input ${errors.password ? 'input-error' : ''}`}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrorMessage('');
                  }}
                />
                <button type="button" onClick={togglePasswordVisibility}>
                  <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
                </button>
              </div>

              {errorMessage && <p className="field-error">{errorMessage}</p>}

              <button type="submit" className="login-page-button">Sign In</button>
              <p className="login-page-p">
                <a href="#" className="login-page-forgot-password" onClick={handleForgotPassword}>Forgot your password?</a>
              </p>
            </form>
          )}
        </div>

        <div className="overlay-container-login">
          <div className="overlay-login">
            <div className="overlay-panel-login overlay-left-login">
              <h1 className="login-page-h1">Welcome Back!</h1>
              <p className="login-page-p">To keep connected with us please login with your personal info</p>
              <button className="login-page-ghost login-page-button" onClick={handleToggle}>Sign In</button>
            </div>
            <div className="overlay-panel-login overlay-right-login">
              <h1 className="login-page-h1">Hello, Friend!</h1>
              <p className="login-page-p">Enter your personal details and start your journey with us</p>
              <button className="login-page-ghost login-page-button" onClick={handleToggle}>Sign Up</button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onClose={handleCloseModal} />
    </div>
  );
};

export default LoginPage;